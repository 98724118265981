import _ from "lodash";
import React from "react";
import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import {
  faBuildings,
  faCircleUser,
  faFileContract,
  faLeaf,
  faMagnifyingGlassChart,
  faSuitcase,
  faUsers,
} from "@fortawesome/pro-light-svg-icons";
import styled from "@emotion/styled";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizedLink } from "gatsby-theme-i18n";
import { Trans } from "@lingui/macro";
// import * as amplitude from "@amplitude/analytics-browser";

import { theme, colors } from "../../theme";
import headerImage from "../../images/campaign/signup2023/Header.png";
import logoImage from "../../images/campaign/signup2023/Logo.png";
import toolsImage from "../../images/campaign/signup2023/Tools.png";
import toolsSmallImage from "../../images/campaign/signup2023/ToolsSmall.png";
import footerLeft from "../../images/campaign/signup2023/FooterLeft.png";
import footerRight from "../../images/campaign/signup2023/FooterRight.png";
import footerBottom from "../../images/campaign/signup2023/footerBottom.png";

import logoAllianz from "../../images/campaign/signup2023/logo_allianz.png";
import logoBancoSantander from "../../images/campaign/signup2023/logo_banco_santander.png";
// import logoBankinter from "../../images/campaign/signup2023/logo_bankinter.png";
// import logoCaixaBank from "../../images/campaign/signup2023/logo_caixa_bank.png";
import logoABCD from "../../images/campaign/signup2023/logo_ABCD.png";
import logoBankOfSingapore from "../../images/campaign/signup2023/logo_bank_of_singapore.png";
import logoDeutscheBank from "../../images/campaign/signup2023/logo_deutsche_bank.png";
import logoFlanklin from "../../images/campaign/signup2023/logo_flanklin.png";
import logoMorganStanley from "../../images/campaign/signup2023/logo_morgan_stanley.png";
import logoSchroders from "../../images/campaign/signup2023/logo_schroders.png";

import CustomizedIcon from "../../components/CustomizedIcon";
import Seo from "../../components/seo";
import provideTrackEvent from "../../core/infrastructure/TrackEvent";

const tools = () => [
  {
    icon: faMagnifyingGlassChart,
    color: "#D50032",
    title: <Trans>Funds and Markets Tools</Trans>,
    description: <Trans>More than 400,000 funds and shares with accurate, up-to-date data and information in real time.</Trans>,
  },
  {
    icon: faLeaf,
    color: "#0E8F61",
    title: <Trans>ESG Solutions</Trans>,
    description: <Trans>An advanced set of solutions that addresses the ESG challenge and meets the regulatory needs of financial institutions.</Trans>,
  },
  { icon: faSuitcase,
    color: "#C22C74",
    title: <Trans>Portfolio management</Trans>,
    description: <Trans>Analyze portfolios, create financial planning scenarios to share with your clients, and generate clear and accurate reports.</Trans>,
  },
  { icon: faFileContract,
    color: "#E7673E",
    title: <Trans>Legal documentation</Trans>,
    description: <Trans>Save time with easy to find and up-to-date regulatory information, covering all your MiFID and regulatory needs.</Trans>,
  },
];

const logos = () => [
  {logo: logoBancoSantander, height: 3},
  {logo: logoAllianz, height: 3},
  {logo: logoMorganStanley, height: 4},
  {logo: logoABCD, height: 4},
  {logo: logoDeutscheBank, height: 3},
  {logo: logoFlanklin, height: 6},
  {logo: logoBankOfSingapore, height: 3.5},
  {logo: logoSchroders, height: 3},
];

const moreInfo = () => [
  { label: <Trans>Privacy Policy</Trans>, path: "/legal-advice/privacy-policy" },
  { label: <Trans>Legal Notice</Trans>, path: "/legal-advice/legal-notice" },
  { label: <Trans>Cookies Policy</Trans>, path: "/legal-advice/cookies-policy" },
];

const buildUrl = (email) => {
  const urlWithEmail = new URL("https://next.allfunds.com/login");
  urlWithEmail.searchParams.append("step", "signup");
  if (!_.isEmpty(email)) urlWithEmail.searchParams.append("email", email);
  return urlWithEmail;
};

const metadata = {
  title: "Allfunds - Signup 2024",
  description: "Accede gratis a la plataforma  de fondos y mercados con información proporcionada directamente por las firmas financieras y las mejores fuentes de información. Utiliza las mejores herramientas para el seguimiento, el análisis y la toma de decisiones de las inversiones.",
};

const validEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+$/;
  if (_.isEmpty(email) || re.test(email)) return true;
  return false;
};

function Signup2023Page() {
  const [email, setEmail] = React.useState("");
  const [registerSubmit, setRegisterSubmit] = React.useState(false);

  React.useEffect(() => {
    document.body.style.margin = 0;
  }, []);

  const handleRegisterSubmit = () => {
    setRegisterSubmit(true);
    if (!validEmail(email)) return;

    setRegisterSubmit(false);
    // amplitude.track(`Sign up - ${window.location.href}`,
    //   {
    //     category: "sign_up",
    //     section: "campaign",
    //   }
    // );
    provideTrackEvent().track({
      eventName: `Sign up - ${window.location.href}`,
      eventCategory: "sign_up",
      eventSection: "campaign",
      eventProperties: { userID: 999999999 },
    });
    window.open(buildUrl(email), "_blank");
  };

  const buildLogoImage = () => {
    return (
      <section>
        <Grid container xs={12} sx={{height: { lg: "17.5rem", md: "12.5rem", sm: "10rem", xs: "5rem"}}}>
          <Grid
            container
            sx={{
            maxWidth: "1440px",
            margin: "auto",
            justifyContent: {md: "space-around", xs: "center"},
          }}>
            <Grid container xs={4} sm={3}>
              <Box width="75%" height="auto">
                <img alt="" src={logoImage} style={{width: "100%", height: "auto"}} />
              </Box>
            </Grid>

            <Grid container xs={7}>
              <img alt="" src={headerImage} style={{width: "60%", height: "auto", position: "absolute", right: 0, top: 0}} />
            </Grid>
          </Grid>
        </Grid>
      </section>
    );
  };

  const buildEmailTextField = () => {
    if (registerSubmit && !validEmail(email)) {
      return (
        <LightTextField
          error
          label={<Trans>Company email</Trans>}
          fullWidth
          onChange={(e) => { setEmail(e.target.value); setRegisterSubmit(false); }}
          value={email}
          variant="filled"
          helperText="Email no es correcto. Inténtelo de nuevo."
          />
      );
    }

    return (
      <LightTextField
        label={<Trans>Company email</Trans>}
        fullWidth
        onChange={(e) => setEmail(e.target.value)}
        value={email}
        variant="filled"
      />
    );
  };

  const buildInputMainSection = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{
          marginTop: "3rem !important",
          padding: "2rem",
          backgroundColor: "#F1F1F3",
          borderRadius: {xs: "0px", sm: "18px"},
        }}
      >
        <CustomizedIcon mb={1} size={44} padding={0} icon={faCircleUser} color={colors.orange.next} backgroundColor="#F1F1F3" />
        <Box textAlign="center">
          <span>
            <Trans>
              <TitleTypography
                fontWeight={700}
                sx={{
                  fontSize: {lg: "2rem", md: "2.5rem", xs: "1.5rem"},
                  lineHeight: {lg: "2.5rem", md: "3.40rem", xs: "2.25rem"},
              }}
                component="span">Access Allfunds Connect <br />for</TitleTypography>
              <TitleTypography
                fontWeight={700}
                sx={{
                  fontSize: {lg: "2rem", md: "2.5rem", xs: "1.5rem"},
                  lineHeight: {lg: "2.5rem", md: "3.40rem", xs: "2.25rem"},
                  color: `${colors.orange.next} !important`,
                }}
                component="span"> free </TitleTypography>
              <TitleTypography
                fontWeight={700}
                sx={{
                  fontSize: {lg: "2rem", md: "2.5rem", xs: "1.5rem"},
                  lineHeight: {lg: "2.5rem", md: "3.40rem", xs: "2.25rem"},
                }}
                component="span">now</TitleTypography>
            </Trans>
          </span>
        </Box>

        <Grid mt={1} container spacing={1} justifyContent="center">
          <Grid item xs={12} sm={8} md={10} lg={10}>
            {buildEmailTextField()}
          </Grid>

          <Grid item xs={10} sm={6} md={5} lg={6}>
            <StyledButton
              fullWidth
              variant="contained"
              style={{backgroundColor: `${colors.orange.next}`, boxShadow: "none"}}
              onClick={() => handleRegisterSubmit()}
            >
              <StyledTypography sx={{padding: "0.5rem", fontWeight: 600, color: "white !important"}}><Trans>Sign up</Trans></StyledTypography>
            </StyledButton>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const buildMainSection = () => {
    return (
      <main>
        <Box sx={{ maxWidth: {lg: "1440px !important", md: "1100px !important"}, margin: "auto"}}>
          <Grid container justifyContent="space-around">
            <Grid
              container
              md={12}
              lg={6}
              justifyContent="center"
            >
              <Grid item xs={12} sx={{margin: {lg: "0 0", md: "0 2rem", sm: "0 2rem", xs: "0 1.5rem"}}}>
                <Box sx={{display: {xs: "block", sm: "flex", md: "block", lg: "block"}}}>
                  <TitleTypography
                    fontWeight={800}
                    sx={{
                      marginRight: "0.25rem",
                      fontSize: {md: "3rem", xs: "1.5rem"},
                      lineHeight: {md: "4rem", xs: "2.25rem"},
                    }}
                  ><Trans> Join the largest </Trans></TitleTypography>
                  <TitleTypography
                    fontWeight={800}
                    sx={{
                      fontSize: {md: "3rem", xs: "1.5rem"},
                      lineHeight: {md: "4rem", xs: "2.25rem"},
                    }}
                  ><Trans> financial platform for </Trans></TitleTypography>
                </Box>
                <TitleTypography
                  fontWeight={800}
                  sx={{
                    fontSize: {md: "3rem", xs: "1.5rem"},
                    lineHeight: {md: "4rem", xs: "2.25rem"},
                  }}
                >
                  <Box sx={{position: "relative", display: {md: "block", xs: "none"}}}><Trans> professional investors</Trans>
                    <StyleSvg xmlns="http://www.w3.org/2000/svg" width="594" height="98" viewBox="0 0 594 98" fill="none" style={{ top: "-0.8rem", left: "-1.9rem"}}>
                      <path d="M309.295 4.2596C224.842 0.156981 90.3593 6.31093 38.953 19.3027C-7.40457 31.0185 -3.64926 69.018 26.8861 74.8082C99.4675 88.5715 448.367 106.141 554.852 85.6282C633.338 70.5087 601.668 -18.3048 281.756 26.1403" stroke="#F49F6C" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
                    </StyleSvg>
                  </Box>
                  <Box sx={{position: "relative", display: {md: "none", xs: "block"}}}><Trans> professional investors</Trans>
                    <StyleSvg xmlns="http://www.w3.org/2000/svg" width="344" height="45" viewBox="0 0 344 45" fill="none" style={{ top: "-0.25rem", left: "-1.5rem"}}>
                      <path className="path" d="M178.64 2.55947C129.777 0.737228 51.9665 3.4706 22.2234 9.2411C-4.59855 14.4449 -2.42577 31.3229 15.2416 33.8948C57.2364 40.0079 259.106 47.8119 320.717 38.7006C366.128 31.9851 347.804 -7.46285 162.706 12.2781" stroke="#F49F6C" strokeWidth="3.18948" strokeLinecap="round" strokeLinejoin="round" />
                    </StyleSvg>
                  </Box>
                </TitleTypography>
                <StyledTypography mt={2}>
                  <Trans>
                    Access the <b>most comprehensive platform for funds and markets</b> with data and information directly from prominent financial institutions and sources.
                  </Trans>
                </StyledTypography>
                <StyledTypography mt={1.5}>
                  <Trans>
                    Use the leading tools for <b>monitoring, analysis and investment decision making.</b>
                  </Trans>
                </StyledTypography>
              </Grid>

              <Grid container mt={2} xs={12} md={9} alignItems="center" justifyContent="center" sx={{display: {md: "block", lg: "none"}}}>
                {buildInputMainSection()}
              </Grid>

              <Grid
                container
                xs={12}
                sx={{
                justifyContent: { md: "center", lg: "space-between"},
                margin: {lg: "0 0", md: "3rem 2rem 0rem", sm: "0", xs: "0 1.5rem"},
              }}>
                <Grid container xs={12} sm={12} md={5} lg={4} mt={2}>
                  <Grid item xs={12} sm={12} md={4} sx={{marginRight: {xs: "0rem", sm: "0rem", md: "1rem", lg: "1rem"}}}>
                    <Box display="flex" justifyContent="center">
                      <CustomizedIcon size={30} padding={1.5} icon={faBuildings} color={colors.orange.next} />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={4} mt={0.5}>
                    <Box display="flex" flexDirection="column" sx={{ alignItems: {xs: "center", sm: "center", md: "start"}}}>
                      <TitleTypography
                        fontWeight={800}
                        sx={{
                          fontSize: { md: "2.25rem", xs: "1.75rem"},
                          lineHeight: { md: "2.75rem", xs: "2.25rem"},
                        }}
                      >
                        2.000
                      </TitleTypography>
                      <StyledTypography mt={0.5}><Trans>Registered entities</Trans></StyledTypography>
                    </Box>
                  </Grid>

                </Grid>

                <Grid container xs={12} sm={12} md={5} lg={6} mt={2} justifyContent="center">
                  <Grid item xs={12} sm={12} md={2} lg={2} sx={{marginRight: {xs: "0rem", sm: "0rem", md: "1rem", lg: "1rem"}}}>
                    <Box display="flex" justifyContent="center">
                      <CustomizedIcon size={30} padding={1.5} icon={faUsers} color={colors.orange.next} />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={9} lg={9} mt={0.5}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      sx={{
                        alignItems: {xs: "center", sm: "center", md: "start"},
                        textAlign: {xs: "center", sm: "center", md: "start"},
                      }}
                    >
                      <TitleTypography
                        fontWeight={800}
                        sx={{
                          fontSize: { md: "2.25rem", xs: "1.75rem"},
                          lineHeight: { md: "2.75rem", xs: "2.25rem"},
                        }}
                      >
                        16.000
                      </TitleTypography>
                      <StyledTypography mt={0.5}>
                        <Trans>Fund selectors, portfolio managers, financial advisors and active bankers</Trans>
                      </StyledTypography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                xs={12}
                mt={2}
                sx={{
                justifyContent: {lg: "start", md: "start", sm: "center", xs: "start"},
                margin: {lg: "0 0", md: "0 2rem", sm: "0 2rem", xs: "1rem 1.5rem"},
              }}>
                <ul>
                  <li>
                    <StyledTypography mt={0.5}>
                      <Trans>Accurate, refined and verified data and information you can trust</Trans>
                    </StyledTypography>
                  </li>
                  <li>
                    <StyledTypography mt={0.5}>
                      <Trans>Management tools adapted to the professional investor</Trans>
                    </StyledTypography>
                  </li>
                  <li>
                    <StyledTypography mt={0.5}>
                      <Trans>Analysis and insights oriented to decision making</Trans>
                    </StyledTypography>
                  </li>
                </ul>

              </Grid>
            </Grid>

            <Grid
              container
              mt={2}
              lg={4}
              alignItems="center"
              justifyContent="center"
              sx={{
                display: {xs: "none", sm: "none", md: "none", lg: "block"},
              }}>
              {buildInputMainSection()}
            </Grid>

          </Grid>
        </Box>
      </main>
    );
  };

  const buildToolsSection = () => {
    return (
      <section>
        <Box sx={{position: "relative"}}>
          <Box sx={{position: "relative", maxWidth: {lg: "1440px !important", md: "1100px !important"}, margin: "auto"}}>
            <Grid container mt={10} alignItems="center" sx={{marginTop: {xs: "2rem", sm: "2rem", md: "2rem"}}}>
              <Grid container xs={12} sx={{display: {lg: "none", md: "block"}}}>
                <Box height="100vw" width="100%" sx={{position: "relative"}}>
                  <img alt="" src={toolsSmallImage} style={{width: "100%", height: "auto", position: "absolute", right: 0, top: 0}} />
                </Box>
              </Grid>
              <Grid lg={0.5}></Grid>
              <Grid
                container
                sm={11}
                md={11}
                lg={7}
                justifyContent="start"
                alignItems="start"
                sx={{
                  marginTop: "5rem !important",
                  paddingLeft: {lg: 0, md: "2rem", sm: "2rem", xs: "1.5rem"},
              }}>
                <Grid item xs={12} justifyContent="center">
                  <TitleTypography
                    sx={{
                      fontSize: {lg: "2rem", md: "2.25rem", xs: "1.5rem"},
                      lineHeight: {lg: "2.5rem", md: "3.125rem", xs: "2.25rem"},
                    }}
                    fontWeight={700}
                  >
                    <Trans>The tools for professional investors at your fingertips</Trans>
                  </TitleTypography>
                  <StyledTypography mt={2}>
                    <Trans>
                      Allfunds Connect provides high-quality solutions to help investment professionals
                      achieve their goals while providing exceptional service to their clients.
                    </Trans>
                  </StyledTypography>
                </Grid>
                <Grid container md={12} lg={11} mt={1} justifyContent="space-between">
                  {tools().map(({ icon, color, title, description }) => (
                    <Grid item xs={12} md={5} mt={3} mr={3}>
                      <StyledCustomizedIcon
                        size={30}
                        padding={1.5}
                        icon={icon}
                        color={color}
                        title={title}
                        description={description}
                      />
                    </Grid>
                    ))}
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box height="45vw" width="40%" sx={{position: "absolute", right: 0, top: 0, display: {lg: "block", md: "none", sm: "none", xs: "none"}}}>
            <img alt="" src={toolsImage} style={{width: "95%", height: "auto", position: "absolute", right: 0, top: 0}} />
          </Box>
        </Box>
      </section>
    );
  };

  const buildClientSection = () => {
    return (
      <section>
        <Grid
          container
          xs={12}
          justifyContent="center"
          sx={{
          marginTop: {
            xs: "5rem !important",
            sm: "5rem !important",
            md: "5rem !important",
            lg: "12.5rem !important",
          },
        }}>
          <Grid xs={12} textAlign="center">
            <TitleTypography
              fontWeight={700}
              sx={{
                fontSize: {lg: "2rem", md: "2.25rem", xs: "1.5rem"},
                lineHeight: {lg: "2.5rem", md: "3.125rem", xs: "2.25rem"},
              }}><Trans>Our clients</Trans></TitleTypography>
          </Grid>
          <Grid container xs={12} sm={10} md={10} lg={10} mt={1} alignItems="center" justifyContent="center">
            {logos().map(({logo, height}) => (
              <Grid item xs={5.5} sm={6} md={6} lg={3} mt={3} justifyContent="center">
                <Box display="flex" justifyContent="center" sx={{height: {xs: `${height * 0.5}rem`, sm: `${height * 0.7}rem`, md: `${height}rem`, lg: `${height}rem`}}}>
                  <img alt="" src={logo} style={{width: "auto", height: "100%"}} />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </section>
    );
  };

  const buildFooter = () => {
    return (
      <footer>
        <Box width="100%" sx={{backgroundColor: "#F1F1F3", position: "relative", height: {lg: "46.5rem", sm: "100%"}}} mt={7.5}>
          <Box sx={{position: "absolute", left: 0, width: "25rem", marginTop: "16.75rem", display: {lg: "block", md: "none", sm: "none", xs: "none"}}}>
            <img alt="" src={footerLeft} style={{width: "100%", height: "auto"}} />
          </Box>
          <Box sx={{position: "absolute", right: 0, width: "25.75rem", display: {lg: "block", md: "none", sm: "none", xs: "none"}}}>
            <img alt="" src={footerRight} style={{width: "100%", height: "auto"}} />
          </Box>

          <Grid xs={12} container justifyContent="center" sx={{ position: "relative", alignItems: "center"}}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
              sx={{
              marginTop: {xs: "4rem", md: "8rem"},
              width: {md: "85%", sm: "75%", xs: "90%"},
            }}>
              <span>
                <Trans>
                  <TitleTypography
                    component="span"
                    sx={{
                      fontSize: {lg: "3rem", md: "2.25rem", xs: "1.5rem"},
                      lineHeight: {lg: "4rem", md: "3.125rem", xs: "2.25rem"},
                      fontWeight: {md: 700, xs: 600},
                    }}
                  >Join our digital platform for</TitleTypography>
                  <TitleTypography
                    component="span"
                    sx={{
                      fontWeight: {md: 700, xs: 600},
                      fontSize: {lg: "3rem", md: "2.25rem", xs: "1.5rem"},
                      lineHeight: {lg: "4rem", md: "3.125rem", xs: "2.25rem"},
                      color: `${colors.orange.next} !important`,
                    }}
                  > free</TitleTypography>
                  <TitleTypography
                    component="span"
                    sx={{
                      fontWeight: {md: 700, xs: 600},
                      fontSize: {lg: "3rem", md: "2.25rem", xs: "1.5rem"},
                      lineHeight: {lg: "4rem", md: "3.125rem", xs: "2.25rem"},
                    }}
                  >   and <br />take your investments to the next level!</TitleTypography>
                </Trans>
              </span>
              <Grid mt={3} md={12} lg={8} container spacing={1} justifyContent="center">
                <Grid item xs={12} sm={8} md={8} lg={8}>
                  {buildEmailTextField()}
                </Grid>

                <Grid item xs={8} sm={5} md={5} lg={5} sx={{marginTop: {lg: "2rem", md: "0.5rem", sx: "1.5rem"}}}>
                  <StyledButton
                    fullWidth
                    variant="contained"
                    style={{backgroundColor: `${colors.orange.next}`, boxShadow: "none"}}
                    onClick={() => handleRegisterSubmit()}
                  >
                    <StyledTypography sx={{padding: "0.5rem", fontWeight: 600, color: "white !important"}}><Trans>Sign up</Trans></StyledTypography>
                  </StyledButton>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Box sx={{display: {lg: "none", md: "block"}, height: {xs: "145vw", sm: "125vw"}}}>
            <img alt="" src={footerBottom} style={{width: "100%", height: "auto"}} />
          </Box>

          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{position: "absolute", bottom: "1.5rem"}}
          >
            <Box display="flex" justifyContent="center" width="80%">
              <Stack
                direction="row"
                spacing={2}
                sx={{ justifyContent: "space-evenly" }}
              >
                {moreInfo().map(({ label, path }) => (
                  <LocalizedLink to={path || "/"}>
                    <Typography variant="comment" sx={{ cursor: "pointer", fontFamily: "\"Inter\", sans-serif" }} color="#0E2340">
                      {label}
                    </Typography>
                  </LocalizedLink>
                ))}
              </Stack>
            </Box>
            <Box display="flex" textAlign="center" sx={{marginTop: {xs: "2rem", sm: "1.5rem", md: "1rem", lg: "0.5rem"}}}>
              <Typography variant="comment" color="#0E2340" sx={{ fontFamily: "\"Inter\", sans-serif", lineHeight: "16px" }}>
                Copyright © 2025 Allfunds Bank S.A.U., Allfunds Bank is a registered trademark.
                All rights reserved
              </Typography>
            </Box>
          </Box>
        </Box>
      </footer>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Seo
        title={metadata.title}
        description={metadata.description}
      />
      { buildLogoImage() }
      { buildMainSection() }
      { buildToolsSection() }
      { buildClientSection() }
      { buildFooter() }

    </ThemeProvider>
  );
}

export default Signup2023Page;

const LightTextField = styled(TextField)`
  > label { 
    color: rgba(14, 35, 64, 0.6);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
  }

  > div {
    border-radius: 8px;
    &:before {
      border-bottom: none !important;
    }
    &:after {
      border-bottom: none !important;
    }
    &:hover {
      border-bottom: none !important;
    }

    > input {
      background-color: white;
      border-radius: 8px;
      font-family: 'Inter', sans-serif;
      height: 16px; 
      line-height: 16px;
      font-size: 16px;
    }
  
    > fieldset {
      border: 2px solid transparent;
    }
  }
`;

const StyledTypography = styled(Typography)`
  color: rgba(14, 35, 64, 0.8);
  font-family: 'Inter', sans-serif;
  line-height: 19.36px;
  font-size: 16px;
`;

const TitleTypography = styled(Typography)`
  font-family: 'Inter', sans-serif;
  color: rgba(14, 35, 64, 1);
`;

const StyleSvg = styled.svg`
  position: absolute; 
  .path {
    stroke-dasharray: 1500;
    stroke-dashoffset: 1500;
    animation-duration: 3s;
    animation-name: dash;
    animation-direction: normal;
    animation-fill-mode: forwards;
    animation-timing-function: linear; 
  }

  @keyframes dash {
    from {
      stroke-dashoffset: 1500;
    }
    to {
      stroke-dashoffset: 3000;
    }
  }
`;

const StyledCustomizedIcon = styled(CustomizedIcon)`
 > div {
  text-align: start; 
  margin: 0;
  
  > p {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: rgba(14, 35, 64, 1);
  }

  > span {
    font-family: 'Inter', sans-serif;
    color: rgba(14, 35, 64, 0.8)
  }
}
`;

const StyledButton = styled(Button)`
  border-radius: 8px;
  &:hover {
    background-color: rgba(244, 108, 29, 1) !important;
  }
`;
